<template>
  <div class="wen">
    <div class="top">根据互联网诊疗活动等相关规定，我院医生为复诊患者开具线上处方，若非复诊，我们仅提供医疗咨询服务</div>
    <div class="describe">请描述您的病情</div>
    <van-cell-group>
      <van-field v-model="message"
                 type="textarea"
                 placeholder="医生您好，有几个问题想咨询一下您"
                 autosize
                 style="height: 200px;" />
    </van-cell-group>
    <div class="potho">
      <van-uploader v-model="fileList"
                    multiple
                    :max-count="3" />
      <span> 上传检查报告或患处照片(最多3张)</span>
    </div>
    <div class="header">
      <van-field readonly
                 clickable
                 label="选择就诊人"
                 :value="patName"
                 @click="patNamePickerShow = true"
                 right-icon="arrow-down" />
      <van-popup v-model="patNamePickerShow"
                 round
                 position="bottom">
        <van-picker show-toolbar
                    :columns="patNameList"
                    @cancel="patNamePickerShow = false"
                    @confirm="patNameConfirm" />
      </van-popup>
    </div>
    <div class="bottom_1">
      <van-button type="info"
                  style="width: 100%;"
                  :disabled="disabled"
                  @click="consult">免&nbsp;&nbsp;费&nbsp;&nbsp;咨&nbsp;&nbsp;询</van-button>
    </div>
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Wen",
  data () {
    return {
      message: "",
      fileList: [],
      patName: "",
      patNamePickerShow: false,
      patNameList: [],
      patNameArray: [],
      patNameIndex: 0,
      baseImage: [],
      option: {},
      disabled: false
    }
  },
  created () {
    if (window.localStorage.getItem("openId") === null) {
      this.getCode();
    } else {
      this.getMembers();
    }
    this.option = this.$route.query

  },
  methods: {
    // 获取code
    getCode () {
      let local = window.location.href;
      let appid = "wxcdae494f7e9422e7";
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent
        (local)
        }&response_type=code&scope=snsapi_base&state=123&#wechat_redirect`;
      // window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxcdae494f7e9422e7&redirect_uri=http://tssywx.tssrmyy.com&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect"
      let str = window.location.href;
      let code = str.split("code=")[1].split("&")[0];
      this.code = code
      this.login(code);
    },
    //获取openId
    async login (code) {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true
      });
      let data = qs.stringify({
        code: code
      });
      const { data: res } = await formPost("/wxapp/login", data);
      this.$toast.clear();
      if (res.code === 0) {
        window.localStorage.setItem("openId", res.data.openid);
        this.getMembers();
      } else {
        this.$toast.fail(res.msg);
      }
    },
    //获取所有建档人
    async getMembers () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      let data = qs.stringify({
        openId: window.localStorage.getItem("openId"),
      });
      const { data: res } = await formPost(
        "/wxapp/userBindRecord/userBindRecord/getUserBindInfos",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.patNameArray = res.data;
        for (let i = 0; i < this.patNameArray.length; i++) {
          this.patNameList.push(res.data[i].name + '(' + res.data[i].patCardNo + ')');
        }
        this.patName = this.patNameList[this.patNameIndex];
      }
      else {
        this.$toast.fail("暂无建档，请先建档");
      }
    },
    patNameConfirm (value) {
      this.patName = value;
      this.patNameIndex = this.patNameList.findIndex(item => {
        return item === value;
      });
      this.patNamePickerShow = false;
    },
    // 提交图文问诊
    async consult () {
      this.disabled = true
      this.fileList.forEach(e => {
        this.baseImage.push(e.content)
      })
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true
      });
      const { data: res } = await jsonPost("/wxapp/his/getInterview", {
        openId: window.localStorage.getItem("openId"),
        patCardNo: this.patNameArray[this.patNameIndex].patCardNo,
        patName: this.patNameArray[this.patNameIndex].name,
        illness: this.message,
        baseImage: this.baseImage,
        patMobile: this.patNameArray[this.patNameIndex].telephone,
        patSex: this.patNameArray[this.patNameIndex].sex,
        patAge: this.patNameArray[this.patNameIndex].age,
        deptCode: this.option.deptCode,
        deptName: this.option.deptName,
        doctorCode: this.option.doctorCode,
        doctorName: this.option.doctorName,
      });
      this.$toast.clear();
      if (res.code == 0) {
        this.$toast.success(res.msg);
        setTimeout(() => {
          this.$router.push({
            path: "/"
          });
        }, 2000);
      } else {
        this.$toast.fail(res.msg);
      }
    }
  }
}
</script>

<style lang="less">
.wen {
  .top {
    background-color: #fdf7eb;
    width: 100%;
    font-size: 26px;
  }
  .describe {
    margin-top: 10px;
    padding: 20px;
  }

  .potho {
    span {
      font-size: 26px;
    }
    border-bottom: 1px solid rgb(201, 191, 191);
  }
  .header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: white;
    margin-top: 20px;
  }
  .header .van-cell {
    border-bottom: 1px solid rgb(201, 191, 191);
    padding: 30px;
  }
  .header .van-cell::after {
    border: 0;
  }
  .header .van-field__label {
    text-align: left;
    font-size: 40px;
  }
  .header .van-field__control {
    text-align: right;
    margin-right: -10px;
    font-size: 40px;
  }
  .header .van-icon {
    font-size: 40px;
  }
  .header .van-picker-column {
    font-size: 40px;
  }
  .header .van-picker__cancel,
  .header .van-picker__confirm {
    font-size: 40px;
    padding: 40px;
  }
  .bottom_1 {
    position: fixed;
    left: 0;
    bottom: 60px;
    width: 100%;
    height: 150px;
    background-color: white;
    border-top: 1px solid #f5f5f5;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 30px;
  }
}
</style>
